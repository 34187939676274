// import React, { useState, useEffect } from 'react'
import Web3 from 'web3';
import { addressWhiteListing, disconnectMetamask, updateAccountDetails, updateRoundDetails } from '../store/actions/actions';;
const ABI = require('../ABI Contract/ABI.json');
const ABI2 = require('../ABI Contract/ABI-2.json');
const ABI3 = require('../ABI Contract/ABI-3.json');
const ABI4 = require('../ABI Contract/ABI-4.json');
const ABI5 = require('../ABI Contract/ABI-5.json');
const ABI6 = require('../ABI Contract/ABI-6.json');
const ABI7 = require('../ABI Contract/ABI-7.json');
const ABI8 = require('../ABI Contract/ABI-7.json');
const ABI9 = require('../ABI Contract/ABI-9.json');
const ABI10 = require('../ABI Contract/ABI_10.json');
const ABI11 = require('../ABI Contract/ABI-11.json');
const ABI12 = require('../ABI Contract/ABI-12.json');
const ABI13 = require('../ABI Contract/ABI-13.json');
const ABI14 = require('../ABI Contract/ABI-14.json');
const ABI15 = require('../ABI Contract/ABI-15.json');
const ABI16 = require('../ABI Contract/ABI-16.json');
const ABI17 = require('../ABI Contract/ABI-17.json');
const ABI18 = require('../ABI Contract/ABI-18.json');
const ABI19 = require('../ABI Contract/ABI-19.json');
const ABI20 = require('../ABI Contract/ABI-20.json');
const ABI21 = require('../ABI Contract/ABI-21.json');
const ABI22 = require('../ABI Contract/ABI-22.json');
const ABI23 = require('../ABI Contract/ABI-23.json');
const ABI24 = require('../ABI Contract/ABI-24.json');
const ABI25 = require('../ABI Contract/ABI-25.json');
const ABI26 = require('../ABI Contract/ABI-26.json');
const ABI27 = require('../ABI Contract/ABI-27.json');
const ABI28 = require('../ABI Contract/ABI-28.json');
const ABI29 = require('../ABI Contract/ABI-29.json');
const ABI32 = require('../ABI Contract/ABI-32.json');
const ABI33 = require('../ABI Contract/ABI-33.json');

// const ABI6USDT = require('../ABI Contract/ABI_6_USDT.json');
const ABI_CLAIM = require('../ABI Contract/ABI-Claim.json');
const ABI_BUSD = require('../ABI Contract/ABI_BUSD.json');
const ABI_USDT = require('../ABI Contract/ABI_USDT.json');


const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
const { ethereum } = window;

const contractAddress = {
    bsc: '0x11d1ac5ec23e3a193e8a491a198f5fc9ee715839',
    busd: '0x55d398326f99059fF775485246999027B3197955',
    bsc_project_new: '0x4eFeF94aeb7BA0002a771696DC395d3fdB1af820',
    bsc_project_polka: '0xa4e1f14c13ece82ee21528e3de0174ec7979220f',
    bsc_project_shoefy: '0x50E10e5Fc5473Bc8E67A5ff5772D38e59f1Ef64D',
    bsc_project_bcmh: '0x6c4FF28AeCf067080c423a0695Dc84ac8CcFe533',
    polygon: '0x5D577881013961c783B36210Ae2299a9421da65f',
    avalanche: '0xc51a97241ad8f77473024855459D386902D1563D',
    eth_claim: '0x50E10e5Fc5473Bc8E67A5ff5772D38e59f1Ef64D',
    polygon_project_fabwelt: '0xF0E65D8Db947526C3EC73c02A0B0CfCA3765Dc47',
    usdt_approve_project_fabwelt: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    bsc_torekko: '0xd22154fdC270d2b44306afbb0D122f69695be872',
    busd_torekko: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_solchick: '0x2578b87ef1D73C3B659Ce8f58168F207EE8Dc797',
    busd_solchick: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_aqarchain: '0x5b21C469B2B85dCaEd7fAD3452c4bfCB9E66d031',
    busd_aqarchain: '0x76A7f1C457FBa1A6D1E4e542E9ABd767860102B8',
    bsc_astra_guild: '0xa32F94F5fF904b900bCC66cD125eA68AfEdc808e',
    busd_astra_guild: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_battle_saga: '0xbB965293980a922797B9d0e56e4ebab9d8b8Cd18',
    busd_battle_saga: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_player_art: '0xc4a8b2e7fbcd4ad45277383a1298401d39410d94',
    busd_player_art: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_operon_origin: '0xe0bc98055772bbce558c1970b1001880892cb802',
    busd_operon_origins: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    bsc_pandora: '0xf78f8b9e05a2d9277611c2ea32a7a56407416a09',
    busd_pandora: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bsc_gameup: '0x9EF9f8DF509127489E91DBB395dFEa8B57023744',
    bsc_vulture_peak: '0xb76F4bA3c97328A30B5C50443eD4c7aA76F659cD',
    bcs_xion_finance: '0x5D20489b9a4abF34eD4126dC4a425C9df7fD8C40',
    bsc_metaverse: '0xdb7D6702239150A01f203F9D939bA5db450AAC7d',
    bsc_mytrade: '0xcA1233493CD0cd6D4a2441eA1EDA70d3207F652D',
    bsc_metaevolutions: '0x37eFFF36560Cdfd00DC82a3bD139AeEF777E991f',
    bsc_defiyield: '0x7Ae79b3C7c8a11E7C95218e17b7d5E0BEbC5C005',
    bsc_gameyoo: '0x36c3aD71929974C372298C9162aE4698cDAf32C0',
    bsc_mechachain: '0x12D55a0097232D5fa903D8292b80c219dd6dd537',
    bsc_deadknight: '0xD9F11083d4342ce5C91F13d5Ab974F4aA8D81133',
    bsc_cens_world: '0x9a0Cb2E4077E13dda06B90939d8F75681dc5E116',
    bsc_neo_cortext_ai: '0x3304cFf8253AD0532878D366F937fde9acd1b46d',
    bsc_quantum_hunter: '0x71df6b321B8bbCBCafeBe0D9273eC735cbCC8e9D',
    bsc_sugar_kingdom: '0xAe0f3c92629B7d353BaBA89981EC12A34E739D13',
    bsc_rgames: '0x32EC4e70496e041299d19bb46FBDCCcEec8ACe16',
    bsc_hauntedgames: '0x6a6FC46f34095C25021725B2E55f9F20d245Caf8',
    bsc_bitsport: '0x81Ce210A3D8b02Fdb8B01Ce95C34B1AB38c6d6e0',

}

let contractInstance;
let accounts;
let isAddressWhiteListingAllowed = false;
let projectNumber;
const networks = {
    main: {
        bsc: {
            name: 'BSC Mainnet',
            chainId: 56,
            tokenName: 'BSC'
        },
        polygon: {
            name: 'POLYGON Mainnet',
            chainId: 137,
            tokenName: 'USDT'
        },
        avalanche: {
            name: 'Avalanche Mainnet',
            chainId: 43114,
            tokenName: 'AVAX'
        },
        ethereum: {
            name: 'Ethereum Mainnet',
            chainId: 1,
            tokenName: 'ETH'
        }
    },
    testnet: {
        bsc: {
            name: 'BSC Testnet',
            chainId: 97,
            tokenName: 'BSC'
        },
        polygon: {
            name: 'POLYGON Testnet',
            chainId: 80001,
            tokenName: 'USDT'
        },
        avalanche: {
            name: 'Avalanche FUJI',
            chainId: 43113,
            tokenName: 'AVAX'
        },
        ethereum: {
            name: 'Sepolia Test Netwok',
            chainId: 11155111,
            tokenName: 'SepoliaETH'
        }
    }
};

export const isMetaMaskInstalled = async (dispatch, network, projectNo) => {
    if (ethereum && ethereum.isMetaMask) {
        await ethereum.enable();
        return checkConnectedNetwork(dispatch, network, projectNo);
    }
    return false;
}

const checkConnectedNetwork = (dispatch, network, projectNo) => {
    return new Promise(async (resolve, reject) => {
        // console.log(network, await web3.eth.getChainId());
        projectNumber = projectNo;
        console.log('chaning the projectNumber', { projectNumber });
        console.log({ network });
        console.log(await web3.eth.getChainId());
        if (network && await web3.eth.getChainId() === network.chainId) {
            subscribeToAccountNetworkChange(dispatch, network, projectNo);
            resolve(InitateConnect(network, dispatch, false, projectNo));
        }
        else {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.numberToHex(network.chainId.toString()) }], // chainId must be in hexadecimal numbers
            });
            subscribeToAccountNetworkChange(dispatch, network, projectNo);
            resolve(InitateConnect(network, dispatch, true, projectNo));
            // reject(`Wrong Network Connected`);
        }
    });
}

const subscribeToAccountNetworkChange = (dispatch, network, projectNo) => {
    ethereum.on('accountsChanged', async () => {
        // if (sessionStorage.networkConnected) console.log(JSON.parse(sessionStorage.networkConnected));
        if (sessionStorage.networkConnected && JSON.parse(sessionStorage.networkConnected).chainId === await web3.eth.getChainId()) {
            InitateConnect(JSON.parse(sessionStorage.networkConnected), dispatch, true, projectNo);
        }
        // else {
        //     sessionStorage.clear();
        //     dispatch(disconnectMetamask());
        // }
    }).on('chainChanged', async () => {
        if (sessionStorage.networkConnected && JSON.parse(sessionStorage.networkConnected).chainId === await web3.eth.getChainId()) {
            InitateConnect(JSON.parse(sessionStorage.networkConnected), dispatch, true, projectNo);
        }
        // else {
        //     sessionStorage.clear();
        //     dispatch(disconnectMetamask());
        // }
    });
}

const InitateConnect = (network, dispatch, isTrue, projectNo) => {
    return new Promise(async (resolve, reject) => {
        accounts = await web3.eth.requestAccounts();
        sessionStorage.setItem("address", accounts[0]);
        let gasPrice = "";
        web3.eth.getGasPrice().then(data => {
            gasPrice = data.toString();
            console.log({ gasPrice });
        });
        console.log({ gasPrice });
        if (accounts.length < 1) {
            reject('No Account Found...');
        }
        else {
            const accountDetails = {
                address: accounts[0],
                balance: await web3.eth.getBalance(accounts[0]),
                whiteListed: false
            }
            const connection = {
                isConnected: true,
                connectedNetwork: {
                    name: network.name,
                    chainId: network.chainId
                },
                networkType: await web3.eth.net.getNetworkType()
            }
            const notifications = {
                error: false,
                message: `${network.name} Network Connected`
            }
            sessionStorage.setItem('networkConnected', JSON.stringify(network));
            console.log('cinstance', contractInstance);
            console.log('final project number', projectNumber);
            if (projectNumber && projectNumber != '') {
                contractInstance = new web3.eth.Contract(
                    projectNumber == 1 ? ABI : projectNumber == 2 ? ABI2 : projectNumber == 3 ? ABI3 :
                        projectNumber == 4 ? network.chainId == 1 || network.chainId == 3 ? ABI_CLAIM : ABI4 : projectNumber == 5 ? ABI5 : projectNumber == 6 ? ABI6 :
                            projectNumber == 7 ? ABI7 : projectNumber == 8 ? ABI8 : projectNumber == 9 ? ABI9 : projectNumber == 10 ? ABI10 :
                                projectNumber == 11 ? ABI11 : projectNumber == 12 ? ABI12 : projectNumber == 13 ? ABI13 :
                                    projectNumber == 14 ? ABI14 : projectNumber == 15 ? ABI15 : projectNumber == 16 ? ABI16 :
                                        projectNumber == 17 ? ABI17 : projectNumber == 18 ? ABI18 : projectNumber == 19 ? ABI19 :
                                            projectNumber == 20 ? ABI20 : projectNumber == 21 ? ABI21 : projectNumber == 22 ? ABI22 :
                                                projectNumber == 23 ? ABI23 : projectNumber == 24 ? ABI24 : projectNumber == 25 ? ABI25 : projectNumber == 27 ? ABI26 :
                                                    projectNumber == 28 ? ABI27 : projectNumber == 29 ? ABI28 : projectNumber == 30 ? ABI29 :
                                                        projectNumber == 32 ? ABI32 : projectNumber == 33 ? ABI33 : null,
                    JSON.parse(sessionStorage.networkConnected).chainId === networks.main.bsc.chainId ?
                        projectNumber == 1 ? contractAddress.bsc : projectNumber == 2 ? contractAddress.bsc_project_new : projectNumber == 3 ? contractAddress.bsc_project_polka :
                            projectNumber == 4 ? contractAddress.bsc_project_shoefy : projectNumber == 5 ?
                                contractAddress.bsc_project_bcmh : projectNumber == 7 ? contractAddress.bsc_torekko :
                                    projectNumber == 8 ? contractAddress.bsc_solchick : projectNumber == 9 ? contractAddress.bsc_aqarchain :
                                        projectNumber == 10 ? contractAddress.bsc_astra_guild : projectNumber == 11 ? contractAddress.bsc_battle_saga :
                                            projectNumber == 12 ? contractAddress.bsc_player_art : projectNumber == 13 ?
                                                contractAddress.bsc_operon_origin : projectNumber == 14 ? contractAddress.bsc_pandora :
                                                    projectNumber == 15 ? contractAddress.bsc_gameup : projectNumber == 16 ? contractAddress.bsc_vulture_peak :
                                                        projectNumber == 17 ? contractAddress.bcs_xion_finance : projectNumber == 18 ? contractAddress.bsc_metaverse :
                                                            projectNumber == 19 ? contractAddress.bsc_mytrade :
                                                                projectNumber == 20 ? contractAddress.bsc_metaevolutions : projectNumber == 21 ? contractAddress.bsc_defiyield :
                                                                    projectNumber == 22 ? contractAddress.bsc_gameyoo : projectNumber == 23 ? contractAddress.bsc_mechachain :
                                                                        projectNumber == 24 ? contractAddress.bsc_deadknight : projectNumber == 25 ? contractAddress.bsc_cens_world :
                                                                            projectNumber == 27 ? contractAddress.bsc_neo_cortext_ai : projectNumber == 28 ? contractAddress.bsc_quantum_hunter :
                                                                                projectNumber == 29 ? contractAddress.bsc_sugar_kingdom : projectNumber == 30 ? contractAddress.bsc_rgames :
                                                                                    projectNumber == 32 ? contractAddress.bsc_hauntedgames : projectNumber == 33 ? contractAddress.bsc_bitsport : null :
                        JSON.parse(sessionStorage.networkConnected).chainId === networks.main.polygon.chainId ? projectNumber == 6 ? contractAddress.polygon_project_fabwelt : contractAddress.polygon_project_fabwelt :
                            JSON.parse(sessionStorage.networkConnected).chainId === networks.main.avalanche.chainId ? contractAddress.avalanche :
                                JSON.parse(sessionStorage.networkConnected).chainId === networks.main.ethereum.chainId ?
                                    projectNumber == 4 && (network.chainId == 1 || network.chainId == 3) ? contractAddress.eth_claim : null : null
                );
                console.log('contract instance created', contractInstance);
            }

            // Create Staking Contract Instance
            // stakingContractInstance = new web3.eth.Contract(ABI_STAKING, stakingContractAddress.staking);
            // mpadTokenContractInstance = new web3.eth.Contract(ABI_STAKING_APPROVE, stakingContractAddress.approve);

            if (isTrue) {
                const result = await roundDetails(dispatch, false);
                dispatch(updateAccountDetails({ connection, accountDetails, notifications }));
                dispatch(updateRoundDetails(result));
                setTimeout(() => {
                    checkAddressWhiteListing(dispatch, isTrue);
                }, 3000);
            }
            else if (!isTrue) {
                resolve({ connection, accountDetails, notifications });
            }
        }
    });
}

export const checkAddressWhiteListing = (dispatch, isTrue) => {
    return new Promise(async (resolve, reject) => {
        if (isAddressWhiteListingAllowed) {
            const isAddressWhiteListed = await contractInstance.methods.checkWhitelistedAddress(accounts[0]).call();
            if (isTrue) {
                console.log('true');
                if (isAddressWhiteListed) {
                    console.log('in');
                    dispatch(addressWhiteListing({ message: "Congratulations! Your Address is Whitelisted", isAddressWhiteListed, isAddressWhiteListingAllowed }));
                } else if (!isAddressWhiteListed) {
                    dispatch(addressWhiteListing({ message: "Your Address is Not Whitelisted!", isAddressWhiteListed, isAddressWhiteListingAllowed }));
                }
            }
            else if (!isTrue) {
                console.log('not true');
                if (isAddressWhiteListed) {
                    resolve({ message: "Congratulations! Your Address is Whitelisted", isAddressWhiteListed, isAddressWhiteListingAllowed })
                } else if (!isAddressWhiteListed) {
                    resolve({ message: "Your Address is Not Whitelisted!", isAddressWhiteListed, isAddressWhiteListingAllowed })
                }
            }
        } else {
            // reject("Address WhiteListing Not Allowed");
            console.log("Address WhiteListing Not Allowed");
        }
    });
}

export const roundDetails = async (dispatch, isTrue) => {
    const p = await progressBarRoundDetails();
    const s = await salesParamRoundDetails();
    const c = await claimTableRoundDetails();
    const u = await userTokenRoundDetails();
    console.log({ c });
    return { p, s, c, u }
}

const progressBarRoundDetails = async () => {
    // console.log({ contractInstance });
    const tokenStatus = await contractInstance.methods.getSoldStatus().call();
    console.log({ tokenStatus });
    return {
        totalAvailable: web3.utils.fromWei(tokenStatus._totalAvailable, 'ether'),
        currentAvailable: web3.utils.fromWei(tokenStatus._currentAvailable, 'ether'),
    }
}

const salesParamRoundDetails = async () => {
    const saleParam = projectNumber == 3 || projectNumber == 4 || projectNumber == 5 || projectNumber == 6 || projectNumber == 7 ||
        projectNumber == 8 || projectNumber == 9 || projectNumber == 10 || projectNumber == 11 ||
        projectNumber == 12 || projectNumber == 13 || projectNumber == 14 || projectNumber == 15 ||
        projectNumber == 16 || projectNumber == 17 || projectNumber == 18 || projectNumber == 19 || projectNumber == 20 || projectNumber == 21 || projectNumber == 22 || projectNumber == 23 || projectNumber == 24 || projectNumber == 25
        || projectNumber == 27 || projectNumber == 28 || projectNumber == 29 || projectNumber == 30 || projectNumber == 32 || projectNumber == 33 ? await contractInstance.methods.getSaleParameter(accounts[0]).call() : await contractInstance.methods.getSaleParameter().call();
    console.log('sales', { saleParam });
    isAddressWhiteListingAllowed = saleParam._whitelistFlag;
    const contractTime = await contractInstance.methods.checkContractTime().call();
    const symbol = projectNumber == 1 ? await contractInstance.methods.name().call() : projectNumber == 2 || projectNumber == 3 ||
        projectNumber == 4 || projectNumber == 5 || projectNumber == 6 || projectNumber == 7 || projectNumber == 8 || projectNumber == 9 ||
        projectNumber == 10 || projectNumber == 11 || projectNumber == 12 || projectNumber == 13 || projectNumber == 14 || projectNumber == 15 ||
        projectNumber == 16 || projectNumber == 17 || projectNumber == 18 || projectNumber == 19 || projectNumber == 20 || projectNumber == 21 || projectNumber == 22 || projectNumber == 23 || projectNumber == 24 || projectNumber == 25
        || projectNumber == 27 || projectNumber == 28 || projectNumber == 29 || projectNumber == 30 || projectNumber == 32 || projectNumber == 33 ? await contractInstance.methods.baseName().call() : null;
    console.log({ projectNumber });
    const tokenPrice = projectNumber == 1 ? await contractInstance.methods.getBnbPricePerToken().call() :
        projectNumber == 2 || projectNumber == 3 || projectNumber == 4 || projectNumber == 5 || projectNumber == 6 ||
            projectNumber == 7 || projectNumber == 8 || projectNumber == 9 || projectNumber == 10 || projectNumber == 11 ||
            projectNumber == 12 || projectNumber == 13 || projectNumber == 14 || projectNumber == 15 ||
            projectNumber == 16 || projectNumber == 17 || projectNumber == 18 || projectNumber == 19 || projectNumber == 20 || projectNumber == 21 || projectNumber == 22 || projectNumber == 23 || projectNumber == 24 || projectNumber == 25
            || projectNumber == 27 || projectNumber == 28 || projectNumber == 29 || projectNumber == 30 || projectNumber == 32 || projectNumber == 33 ? await contractInstance.methods.getViewPricePerToken().call() : null;
    console.log({ tokenPrice });
    const diff = saleParam._endTime - contractTime;
    if (diff < 0) {
        var endTime = {
            h: 0,
            m: 0,
            s: 0
        }
    } else {
        var endTime = {
            h: Math.floor(diff / 60 / 60),
            m: Math.floor(diff / 60) - (Math.floor(diff / 60 / 60) * 60),
            s: diff % 60
        }
    }
    return {
        endTime,
        startTime: saleParam._startTime,
        minAmount: web3.utils.fromWei(saleParam._minimumAmount, 'ether'),
        maxAmount: web3.utils.fromWei(saleParam._maximumAmount, 'ether'),
        saleId: saleParam._saleId,
        isAddressWhiteListedAllowed: saleParam._whitelistFlag,
        symbol,
        tokenPrice: projectNumber == 5 ? tokenPrice : web3.utils.fromWei(tokenPrice, 'ether')
    }
}

const claimTableRoundDetails = async () => {
    console.log({ projectNumber });
    const userTokenAllocations = projectNumber == 1 ? await contractInstance.methods.getUserTokensBy(accounts[0]).call() :
        projectNumber == 2 || projectNumber == 3 || projectNumber == 4 || projectNumber == 6 || projectNumber == 7 || projectNumber == 8 ||
            projectNumber == 9 || projectNumber == 10 || projectNumber == 11 || projectNumber == 12 ||
            projectNumber == 13 || projectNumber == 14 || projectNumber == 15 || projectNumber == 16 || projectNumber == 17 ||
            projectNumber == 18 || projectNumber == 19 || projectNumber == 20 ||
            projectNumber == 21 || projectNumber == 22 || projectNumber == 23 || projectNumber == 24 || projectNumber == 25 || projectNumber == 27 || projectNumber == 28 || projectNumber == 29 || projectNumber == 30 || projectNumber == 32 || projectNumber == 33 ? await contractInstance.methods.getUserTokensByAdd(accounts[0]).call() : null;
    console.log({ userTokenAllocations });
    const claimTokensHistory = projectNumber == 1 || projectNumber == 2 || projectNumber == 3 || projectNumber == 4 || projectNumber == 6 ||
        projectNumber == 7 || projectNumber == 8 || projectNumber == 9 || projectNumber == 10 || projectNumber == 11 ||
        projectNumber == 12 || projectNumber == 13 || projectNumber == 14 || projectNumber == 15 || projectNumber == 16 || projectNumber == 17 ||
        projectNumber == 18 || projectNumber == 19 || projectNumber == 20 || projectNumber == 21 || projectNumber == 22 || projectNumber == 23 || projectNumber == 24 || projectNumber == 25
        || projectNumber == 27 || projectNumber == 28 || projectNumber == 29 || projectNumber == 30 || projectNumber == 32 || projectNumber == 33 ? await contractInstance.methods.getClaimedTokensHistory(accounts[0]).call() : null;
    console.log({ claimTokensHistory });
    const claimDates = await contractInstance.methods.getClaimDates().call();
    console.log({ claimDates });
    return {
        userTokenAllocations: projectNumber == 1 || projectNumber == 11 || projectNumber == 12 ?
            {
                _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                _div4: web3.utils.fromWei(userTokenAllocations._div4, 'ether'),
                _div5: 0
            } : projectNumber == 2 ? {
                _div1: web3.utils.fromWei(userTokenAllocations, 'ether'),
                _div2: 0,
                _div3: 0,
                _div4: 0,
                _div5: 0
            } : projectNumber == 3 || projectNumber == 4 || projectNumber == 15 || projectNumber == 16 || projectNumber == 20 || projectNumber == 30 ? {
                _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                _div4: web3.utils.fromWei(userTokenAllocations._div4, 'ether'),
                _div5: web3.utils.fromWei(userTokenAllocations._div5, 'ether')
            } :
                projectNumber == 6 ? {
                    _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                    _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                    _div3: 0,
                    _div4: 0,
                    _div5: 0
                } : projectNumber == 7 || projectNumber == 25 || projectNumber == 28 || projectNumber == 29 ? {
                    _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                    _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                    _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                    _div4: 0,
                    _div5: 0
                } : projectNumber == 9 ? {
                    _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                    _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                    _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                    _div4: web3.utils.fromWei(userTokenAllocations._div4, 'ether'),
                    _div5: web3.utils.fromWei(userTokenAllocations._div5, 'ether'),
                    _div6: web3.utils.fromWei(userTokenAllocations._div6, 'ether'),
                    _div7: web3.utils.fromWei(userTokenAllocations._div7, 'ether')
                } : projectNumber == 14 || projectNumber == 18 ? {
                    _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                    _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                    _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                    _div4: web3.utils.fromWei(userTokenAllocations._div4, 'ether'),
                    _div5: web3.utils.fromWei(userTokenAllocations._div5, 'ether'),
                    _div6: web3.utils.fromWei(userTokenAllocations._div6, 'ether'),
                    _div7: 0
                } : projectNumber == 33 ? {
                    _div1: web3.utils.fromWei(userTokenAllocations._div1, 'ether'),
                    _div2: web3.utils.fromWei(userTokenAllocations._div2, 'ether'),
                    _div3: web3.utils.fromWei(userTokenAllocations._div3, 'ether'),
                    _div4: web3.utils.fromWei(userTokenAllocations._div4, 'ether'),
                    _div5: web3.utils.fromWei(userTokenAllocations._div5, 'ether'),
                    _div6: web3.utils.fromWei(userTokenAllocations._div6, 'ether'),
                    _div7: web3.utils.fromWei(userTokenAllocations._div7, 'ether'),
                    _div8: web3.utils.fromWei(userTokenAllocations._div8, 'ether'),
                    _div9: web3.utils.fromWei(userTokenAllocations._div9, 'ether'),
                } :
                    {
                        _div1: 0,
                        _div2: 0,
                        _div3: 0,
                        _div4: 0,
                        _div5: 0
                    }
        ,
        claimTokensHistory:
            projectNumber == 1 || projectNumber == 11 || projectNumber == 12 ?
                {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: web3.utils.fromWei(claimTokensHistory.r4, 'ether'),
                    r5: 0
                } :
                projectNumber == 2 ? {
                    r1: web3.utils.fromWei(claimTokensHistory, 'ether'),
                    r2: 0,
                    r3: 0,
                    r4: 0,
                    r5: 0
                } : projectNumber == 3 || projectNumber == 4 || projectNumber == 15 || projectNumber == 16 || projectNumber == 20 || projectNumber == 30 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: web3.utils.fromWei(claimTokensHistory.r4, 'ether'),
                    r5: web3.utils.fromWei(claimTokensHistory.r5, 'ether')
                } : projectNumber == 6 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: 0,
                    r4: 0,
                    r5: 0
                } : projectNumber == 7 || projectNumber == 25 || projectNumber == 28 || projectNumber == 29 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: 0,
                    r5: 0
                } : projectNumber == 9 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: web3.utils.fromWei(claimTokensHistory.r4, 'ether'),
                    r5: web3.utils.fromWei(claimTokensHistory.r5, 'ether'),
                    r6: web3.utils.fromWei(claimTokensHistory.r6, 'ether'),
                    r7: web3.utils.fromWei(claimTokensHistory.r7, 'ether')
                } : projectNumber == 14 || projectNumber == 18 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: web3.utils.fromWei(claimTokensHistory.r4, 'ether'),
                    r5: web3.utils.fromWei(claimTokensHistory.r5, 'ether'),
                    r6: web3.utils.fromWei(claimTokensHistory.r6, 'ether'),
                    r7: 0
                } : projectNumber == 33 ? {
                    r1: web3.utils.fromWei(claimTokensHistory.r1, 'ether'),
                    r2: web3.utils.fromWei(claimTokensHistory.r2, 'ether'),
                    r3: web3.utils.fromWei(claimTokensHistory.r3, 'ether'),
                    r4: web3.utils.fromWei(claimTokensHistory.r4, 'ether'),
                    r5: web3.utils.fromWei(claimTokensHistory.r5, 'ether'),
                    r6: web3.utils.fromWei(claimTokensHistory.r6, 'ether'),
                    r7: web3.utils.fromWei(claimTokensHistory.r7, 'ether'),
                    r8: web3.utils.fromWei(claimTokensHistory.r8, 'ether'),
                    r9: web3.utils.fromWei(claimTokensHistory.r9, 'ether'),
                } :
                    {
                        r1: 0,
                        r2: 0,
                        r3: 0,
                        r4: 0,
                        r5: 0
                    }
        ,
        claimDates: projectNumber == 1 || projectNumber == 11 || projectNumber == 12 ?
            {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: Number(claimDates._d4) !== 0 ? (new Date(Number(claimDates._d4 + "000"))).toString() : '0/0/0',
                _d5: 0,
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: claimDates._d4 + "000",
                d5: 0
            } : projectNumber == 2 ? {
                _d1: Number(claimDates) !== 0 ? (new Date(Number(claimDates + "000"))).toString() : '0/0/0',
                _d2: 0,
                _d3: 0,
                _d4: 0,
                _d5: 0,
                d1: claimDates + "000",
                d2: 0,
                d3: 0,
                d4: 0,
                d5: 0
            } : projectNumber == 3 || projectNumber == 4 || projectNumber == 15 || projectNumber == 16 || projectNumber == 20 || projectNumber == 30 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: Number(claimDates._d4) !== 0 ? (new Date(Number(claimDates._d4 + "000"))).toString() : '0/0/0',
                _d5: Number(claimDates._d5) !== 0 ? (new Date(Number(claimDates._d5 + "000"))).toString() : '0/0/0',
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: claimDates._d4 + "000",
                d5: claimDates._d5 + "000",
            } : projectNumber == 6 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: 0,
                _d4: 0,
                _d5: 0,
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: 0,
                d4: 0,
                d5: 0,
            } : projectNumber == 7 || projectNumber == 25 || projectNumber == 28 || projectNumber == 29 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: 0,
                _d5: 0,
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: 0,
                d5: 0,
            } : projectNumber == 9 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: Number(claimDates._d4) !== 0 ? (new Date(Number(claimDates._d4 + "000"))).toString() : '0/0/0',
                _d5: Number(claimDates._d5) !== 0 ? (new Date(Number(claimDates._d5 + "000"))).toString() : '0/0/0',
                _d6: Number(claimDates._d6) !== 0 ? (new Date(Number(claimDates._d6 + "000"))).toString() : '0/0/0',
                _d7: Number(claimDates._d7) !== 0 ? (new Date(Number(claimDates._d7 + "000"))).toString() : '0/0/0',
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: claimDates._d4 + "000",
                d5: claimDates._d5 + "000",
                d6: claimDates._d6 + "000",
                d7: claimDates._d7 + "000"
            } : projectNumber == 14 || projectNumber == 18 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: Number(claimDates._d4) !== 0 ? (new Date(Number(claimDates._d4 + "000"))).toString() : '0/0/0',
                _d5: Number(claimDates._d5) !== 0 ? (new Date(Number(claimDates._d5 + "000"))).toString() : '0/0/0',
                _d6: Number(claimDates._d6) !== 0 ? (new Date(Number(claimDates._d6 + "000"))).toString() : '0/0/0',
                _d7: 0,
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: claimDates._d4 + "000",
                d5: claimDates._d5 + "000",
                d6: claimDates._d6 + "000",
                d7: 0
            } : projectNumber == 33 ? {
                _d1: Number(claimDates._d1) !== 0 ? (new Date(Number(claimDates._d1 + "000"))).toString() : '0/0/0',
                _d2: Number(claimDates._d2) !== 0 ? (new Date(Number(claimDates._d2 + "000"))).toString() : '0/0/0',
                _d3: Number(claimDates._d3) !== 0 ? (new Date(Number(claimDates._d3 + "000"))).toString() : '0/0/0',
                _d4: Number(claimDates._d4) !== 0 ? (new Date(Number(claimDates._d4 + "000"))).toString() : '0/0/0',
                _d5: Number(claimDates._d5) !== 0 ? (new Date(Number(claimDates._d5 + "000"))).toString() : '0/0/0',
                _d6: Number(claimDates._d6) !== 0 ? (new Date(Number(claimDates._d6 + "000"))).toString() : '0/0/0',
                _d7: Number(claimDates._d7) !== 0 ? (new Date(Number(claimDates._d7 + "000"))).toString() : '0/0/0',
                _d8: Number(claimDates._d8) !== 0 ? (new Date(Number(claimDates._d8 + "000"))).toString() : '0/0/0',
                _d9: Number(claimDates._d9) !== 0 ? (new Date(Number(claimDates._d9 + "000"))).toString() : '0/0/0',
                d1: claimDates._d1 + "000",
                d2: claimDates._d2 + "000",
                d3: claimDates._d3 + "000",
                d4: claimDates._d4 + "000",
                d5: claimDates._d5 + "000",
                d6: claimDates._d6 + "000",
                d7: claimDates._d7 + "000",
                d8: claimDates._d8 + "000",
                d9: claimDates._d9 + "000",
            } :
                {
                    _d1: 0,
                    _d2: 0,
                    _d3: 0,
                    _d4: 0,
                    _d5: 0,
                    d1: 0,
                    d2: 0,
                    d3: 0,
                    d4: 0,
                    d5: 0
                }
    }
}

const userTokenRoundDetails = async () => {
    const userAmountPurchased = await contractInstance.methods.getAmountPurchased(accounts[0]).call();
    return {
        userAmountPurchased: {
            _allocation: web3.utils.fromWei(userAmountPurchased._allocation, 'ether'),
            _contribution: web3.utils.fromWei(userAmountPurchased._contribution, 'ether')
        }
    }
}

export const InitiateTransaction = async (transactionValue) => {
    const tokenValue = await contractInstance.methods.getViewPricePerToken().call();
    console.log({ tokenValue });
    return new Promise(async (resolve, reject) => {
        if (projectNumber == 13 || projectNumber == 17) {
            const newContractInstance = new web3.eth.Contract(ABI_BUSD, contractAddress.busd);
            const approvedAmount = await newContractInstance.methods.allowance(accounts[0].toString(), contractAddress.bsc_bitsport).call();
            // If transaction amount is already approved
            let gasPrice = "";
            web3.eth.getGasPrice().then(data => {
                gasPrice = data.toString();
                console.log({ gasPrice });
            });
            if (web3.utils.toWei((Number(transactionValue) * Number(web3.utils.fromWei(tokenValue.toString(), 'ether'))).toString(), 'ether') <= Number(approvedAmount)) {
                // if (Number(web3.utils.toWei((transactionValue * await contractInstance.methods.getViewPricePerToken().call()).toString(), 'ether')) <= Number(approvedAmount)) {
                // contractInstance.methods.buyTokens(web3.utils.toWei(transactionValue.toString(), 'ether')).send({
                contractInstance.methods.buyTokens(transactionValue).send({
                    from: accounts[0]
                }).on('error', (error) => {
                    reject(error);
                }).on('transactionHash', (data) => {
                    // console.log('transactionData', data);
                }).on('receipt', async (data) => {
                    // console.log('reciept', data);
                    const c = await claimTableRoundDetails();
                    const p = await progressBarRoundDetails();
                    const u = await userTokenRoundDetails();
                    resolve({ tokens: transactionValue, c, p, u, hash: data });
                }).on('confirmation', async (data) => {
                    // console.log('confirmation', data);
                });
            } else {
                const approvedValue = await newContractInstance.methods.approve(contractAddress.bsc_bitsport, web3.utils.toWei((Number(transactionValue) * Number(web3.utils.fromWei(tokenValue.toString(), 'ether'))).toString(), 'ether')).send({
                    // const approvedValue = await newContractInstance.methods.approve(contractAddress.bsc_pandora, web3.utils.toWei((transactionValue * await contractInstance.methods.getViewPricePerToken().call()).toString(), 'ether')).send({
                    from: accounts[0]
                }).on('error', (error) => {
                    console.log('error', error);
                }).on('transactionHash', (data) => {
                    // console.log('transactionHash', data);
                }).on('receipt', async (data) => {
                    console.log('receipt approval', data, data.status);
                    // if (data.status) {
                    // }
                }).on('confirmation', async (data) => {
                    // console.log('confirmation', data);    // // resolve(data);
                });
                console.log({ approvedValue });
                if (approvedValue.status) {
                    let gasPrice = "";
                    web3.eth.getGasPrice().then(data => {
                        gasPrice = data.toString();
                        console.log({ gasPrice });
                    });
                    // contractInstance.methods.buyTokens(web3.utils.toWei(transactionValue.toString(), 'ether')).send({
                    contractInstance.methods.buyTokens(transactionValue).send({
                        from: accounts[0]
                    }).on('error', (error) => {
                        reject(error);
                    }).on('transactionHash', (data) => {
                        // console.log('transactionData', data);
                    }).on('receipt', async (data) => {
                        // console.log('reciept', data);
                        const c = await claimTableRoundDetails();
                        const p = await progressBarRoundDetails();
                        const u = await userTokenRoundDetails();
                        resolve({ tokens: transactionValue, c, p, u, hash: data });
                    }).on('confirmation', async (data) => {
                        // console.log('confirmation', data);
                    });
                }
            }
        } else {
            console.log('inside project 32');
            const newContractInstance = new web3.eth.Contract(ABI_BUSD, contractAddress.busd);
            const approvedAmount = await newContractInstance.methods.allowance(accounts[0].toString(), contractAddress.bsc_bitsport).call();
            console.log({ approvedAmount });
            // If transaction amount is already approved
            // if (web3.utils.toWei((Number(transactionValue) * Number(web3.utils.fromWei(tokenValue.toString(), 'ether'))).toString(), 'ether') <= Number(approvedAmount)) {
            let gasPrice = "";
            web3.eth.getGasPrice().then(data => {
                gasPrice = data.toString();
                console.log({ gasPrice });
            });
            console.log({ transactionValue });
            if (Number(transactionValue) <= Number(web3.utils.fromWei(approvedAmount, 'ether'))) {
                console.log('value is approved');
                contractInstance.methods.buyTokens(web3.utils.toWei(transactionValue.toString(), 'ether')).send({
                    // contractInstance.methods.buyTokens(transactionValue).send({
                    from: accounts[0]
                }).on('error', (error) => {
                    reject(error);
                }).on('transactionHash', (data) => {
                    // console.log('transactionData', data);
                }).on('receipt', async (data) => {
                    // console.log('reciept', data);
                    const c = await claimTableRoundDetails();
                    const p = await progressBarRoundDetails();
                    const u = await userTokenRoundDetails();
                    resolve({ tokens: transactionValue, c, p, u, hash: data });
                }).on('confirmation', async (data) => {
                    // console.log('confirmation', data);
                });
            } else {
                // const approvedValue = await newContractInstance.methods.approve(contractAddress.bsc_bitsport, web3.utils.toWei((Number(transactionValue) * Number(web3.utils.fromWei(tokenValue.toString(), 'ether'))).toString(), 'ether')).send({
                console.log({ accounts });
                console.log('address', contractAddress.bsc_bitsport);
                const approvedValue = await newContractInstance.methods.approve(contractAddress.bsc_bitsport, web3.utils.toWei((transactionValue).toString(), 'ether')).send({
                    from: accounts[0]
                }).on('error', (error) => {
                    console.log('error', error);
                }).on('transactionHash', (data) => {
                    // console.log('transactionHash', data);
                }).on('receipt', async (data) => {
                    console.log('receipt approval', data, data.status);
                    // if (data.status) {
                    // }
                }).on('confirmation', async (data) => {
                    // console.log('confirmation', data);    // // resolve(data);
                });
                console.log({ approvedValue });
                if (approvedValue.status) {
                    let gasPrice = "";
                    web3.eth.getGasPrice().then(data => {
                        gasPrice = data.toString();
                        console.log({ gasPrice });
                    });
                    contractInstance.methods.buyTokens(web3.utils.toWei(transactionValue.toString(), 'ether')).send({
                        // contractInstance.methods.buyTokens(transactionValue).send({
                        from: accounts[0]
                    }).on('error', (error) => {
                        reject(error);
                    }).on('transactionHash', (data) => {
                        // console.log('transactionData', data);
                    }).on('receipt', async (data) => {
                        // console.log('reciept', data);
                        const c = await claimTableRoundDetails();
                        const p = await progressBarRoundDetails();
                        const u = await userTokenRoundDetails();
                        resolve({ tokens: transactionValue, c, p, u, hash: data });
                    }).on('confirmation', async (data) => {
                        // console.log('confirmation', data);
                    });
                }
            }

        }
        // let gasPrice = "";
        // web3.eth.getGasPrice().then(data => {
        //     gasPrice = data.toString();
        //     console.log({ gasPrice });
        // });
        // contractInstance.methods.buyTokens().send({
        //     from: accounts[0],
        //     gasPrice,
        //     gas: 200000,
        //     value: transactionValue * 1000000000000000000
        // }).on('error', (error) => {
        //     reject(error);
        // }).on('transactionHash', (data) => {
        //     // console.log('transactionData', data);
        // }).on('receipt', async (data) => {
        //     // console.log('reciept', data);
        //     const c = await claimTableRoundDetails();
        //     const p = await progressBarRoundDetails();
        //     const u = await userTokenRoundDetails();
        //     resolve({ tokens: transactionValue, c, p, u, hash: data });
        // }).on('confirmation', async (data) => {
        //     // console.log('confirmation', data);
        // });
    });
}

export const InitateClaimTokens = async (id) => {
    console.log({ id });
    return new Promise(async (resolve, reject) => {
        let gasPrice = "";
        web3.eth.getGasPrice().then(data => {
            gasPrice = data.toString();
            console.log({ gasPrice });
        });
        var block = await web3.eth.getBlock("latest");
        // console.log(block.gasLimit / block.transactions.length);
        await contractInstance.methods.claimPurchasedTokens(id).send({
            from: accounts[0],
            gasPrice,
            gas: 600000,
            value: ''
        }).on('error', (error) => {
            reject(error);
        }).on('transactionHash', (data) => {
            // resolve({ tokens: transactionValue, hash: data });
        }).on('receipt', async (data) => {
            console.log(data);
            const c = await claimTableRoundDetails()
            resolve({ c });
        }).on('confirmation', (data) => {
            // console.log(data);
        });
    })
}

const pad = (i) => {
    return ('0' + i).slice(-2)
}


// https://testnet.bscscan.com/address/0xf2038dac5ddf6051e0fb5a7742aab65f0e0b8c1a/#readContract